import Cookies from 'js-cookie'
import { DEFAULT_FRACTION_DIGITS } from '../appConstants'
import { Currency, MoneyValue } from '../types'

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const [head, body] = dataUrl.split(',')
  const [, mime] = head.match(/:(.*?);/) || []
  const bstr = atob(body)
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n) {
    n -= 1
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export const includesSubstringCaseInsensitive = (baseString: string, substring: string) =>
  baseString.toLowerCase().includes(substring.toLowerCase())

export const moneyValueToReadableValue = (
  moneyValue: Partial<MoneyValue> | undefined,
  currency: Currency,
) => {
  if (!moneyValue || moneyValue.amount === undefined) return ''
  const fractionDigits = currency.fractionDigits ?? DEFAULT_FRACTION_DIGITS
  return String(moneyValue.amount / Math.pow(10, fractionDigits))
}

/**
 * This function is used to make sure that you switch over all variants in union type
 * for example you have such type to represent state of loadable data from BE:
 *
 *  type LoadableData<T> =
 *     | { type: 'loading'}
 *     | {type: 'loaded', data: T  }
 *     | {type: 'error', error: Error}
 *
 *  you can render this smth like this:
 *
 *  const state: LoadableData<Account> = ...
 *
 *  switch(state.type){
 *    case 'loading':{
 *      return renderLoading()
 *    }
 *    case 'loaded':{
 *      return renderData(state.data)
 *    }
 *    case 'error':{
 *      return renderError()
 *    }
 *    default:
 *      // this statement never should be reached so TS will not show any error.
 *      notReachable(state)
 *  }
 *
 *  but later on if you decide to add one more variant to LoadableData {type: 'not_started'} to represet state where no one even start loading your data
 *  now you should search for all places to update switch case. With notReachable helper TS will give you an error in each place where you need to update code
 *  so extension of types will be statically checked
 *
 * @param _
 */
export const notReachable = (_: never): never => {
  throw new Error(`should never be reached ${_}`)
}

export const getBrowserLocale = () => {
  const revLanguage = Cookies.get('rev_language')

  return (
    revLanguage ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    'en-GB'
  ).replace('-', '_')
}

export const isHTML = (str: string) => {
  const doc = new DOMParser().parseFromString(str, 'text/html')
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1)
}

export const getFlagForCountry = (countryCode?: string) =>
  `https://assets.revolut.com/assets/flags/${countryCode}.svg`

export const isAbsoluteURL = (url: string) =>
  url.startsWith('http://') || url.startsWith('https://')
