import React from 'react'

import { BottomSheet, Description, Header, Image, Icon, Avatar } from '@revolut/ui-kit'
import { BottomPromptView } from 'types'

import { HtmlStringComponent } from '../Controls/common'
import FlowActionButtons from '../FlowActionButtons'
import LegacyBottomPromptItems from './LegacyBottomPromptItems'
import BottomPromptComponentItems from './BottomPromptComponentItems'

export type Props = {
  promptView: BottomPromptView
  onClose: () => void
  onActionButtonClick: (actionId?: string) => void
}

const BottomPrompt = ({ promptView, onClose, onActionButtonClick }: Props) => {
  const hasIcon = Boolean(promptView.image?.icon?.name)
  const hasFlag = Boolean(!hasIcon && promptView.image?.icon?.countryCode)
  const hasImageUrl = Boolean(!hasIcon && !hasFlag && promptView.image?.url)

  return (
    <BottomSheet open onClose={onClose}>
      <Header variant="bottom-sheet">
        <Header.Title>{promptView.title}</Header.Title>
        {promptView.image && (
          <Header.Actions>
            {hasIcon && <Icon name={promptView.image?.icon?.name} size={64} />}
            {hasFlag && <Avatar size={60} image={promptView.image?.icon?.url} />}
            {hasImageUrl && <Image src={promptView.image?.url} size={64} />}
          </Header.Actions>
        )}
      </Header>
      {promptView.subtitleHtml && (
        <Description marginBottom="s-16">
          <HtmlStringComponent htmlString={promptView.subtitleHtml} />
        </Description>
      )}
      {promptView.items.length > 0 ? (
        <LegacyBottomPromptItems items={promptView.items} />
      ) : (
        <BottomPromptComponentItems items={promptView.componentItems} />
      )}

      <BottomSheet.Actions>
        <FlowActionButtons
          onClick={onActionButtonClick}
          actions={promptView.actions}
          onClose={onClose}
        />
      </BottomSheet.Actions>
    </BottomSheet>
  )
}

export default BottomPrompt
