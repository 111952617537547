import { useQuery, UseQueryResult } from 'react-query'
import { EntitiesSummaryHeader } from 'types'

import { useApi } from '../../../providers'

export const useEntitiesSummaryQuery = (
  formId: string,
  itemId: string,
  requestParams: Record<string, string>,
): UseQueryResult<EntitiesSummaryHeader | undefined> => {
  const { getEntitiesSummary } = useApi()

  const entitiesSummaryQuery = useQuery(
    ['entitiesSummary', formId, itemId],
    () => getEntitiesSummary?.(formId, itemId, requestParams),
    { enabled: !!getEntitiesSummary },
  )

  return entitiesSummaryQuery
}
