import { ReactNode } from 'react'
import {
  CameraImageSource,
  DialogType,
  FlowState,
  FlowViewItemImageContent,
  FlowViewItemType,
  FlowViewType,
  TextStyle,
  UploadState,
  IconCode,
  DateTimeExpressionType,
  FilterValueType,
  PropertyFilterType,
  ListItemStyle,
  ListLinkStyle,
  TransactionState,
} from 'appConstants'
import { IconName } from '@revolut/ui-kit'

export type Flow = {
  id: string
  currentViewId?: string
  name?: string
  state?: FlowState
  views?: FlowView[]
  attributes?: Attributes
  parameters?: FlowParameters
}

export type FlowView =
  | FormView
  | BottomPromptView
  | PromptView
  | StatusDialogView
  | DeepLinkView

export type FormView = {
  id: string
  type: FlowViewType.Form
  nextText: string
  actions: Action[]
  headerAction?: Action
  title: string
  subtitle?: string
  subtitleHtml?: HtmlString
  footer?: HtmlString
  items: FlowViewItem[]
  next: FlowViewNext[]
  previousViewId?: string
  secondaryDescription?: FormViewSecondaryDescription
}

export type FormViewSecondaryDescription = {
  id: string
  label: string
  title: string
  subtitle: string
  subtitleHtml?: HtmlString
  buttonTitle: string
  items: FormViewSecondaryDescriptionItem[]
}

export type FormViewSecondaryDescriptionItem = {
  id: string
  title: string
  description?: string
  descriptionHtml?: HtmlString
  icon?: Icon
  link?: FormViewSecondaryDescriptionLink
}

export type FormViewSecondaryDescriptionLink = {
  title: string
  deeplink: string
  hyperlink?: string
}

export type Action = {
  id: string
  title: string
  deeplink?: string
  hyperlink?: string
  selected?: boolean
}

export interface CloseableAction extends Action {
  dismissOnSelect?: boolean
}

export type StatusDialogView = {
  id: string
  type: FlowViewType.StatusDialog
  dialogType?: DialogType
  title?: string
  description?: string
  dialogActionCaption?: string
  dialogActionDeepLink?: string
  dialogActionHyperlink?: string
}

export type BottomPromptView = {
  type: FlowViewType.BottomPrompt
  id: string
  title?: string
  subtitleHtml?: HtmlString
  image?: {
    url?: string
    icon?: Icon
  }
  items: BottomPromptListItem[]
  componentItems: FlowViewItemStatic[]
  actions: CloseableAction[]
  next: FlowViewNext[]
  previousViewId?: string
}

export type PromptView = {
  type: FlowViewType.Prompt
  id: string
  closeable: boolean
  title: string
  subtitleHtml: HtmlString
  image?: {
    url?: string
    icon?: Icon
  }
  next: FlowViewNext[]
  actions: Action[]
  previousViewId?: string
}

export type Icon = { name: IconName; countryCode?: string; url: string }

export type BottomPromptListItem = {
  icon: Icon
  textHtml: HtmlString
  visible?: string
}

export type DeepLinkView = {
  id: string
  type: FlowViewType.DeepLink
  closeForm: boolean
  webLink: string
}

export type FlowViewNext = {
  id: FlowView['id']
  conditions?: {}[]
}

export type HtmlString = {
  type: 'HTML_STRING'
  value: string
}

export type Attributes = {
  failedSubmissionMessage: string
  destination: 'FORM_BUILDER' | 'CHAT'
} & Record<string, string>

export type FlowParameters = Record<string, unknown>

export type Country = {
  code: string
  description: string
}

export type Currency = {
  code: string
  countryCode: string
  description?: string
  fractionDigits?: number
}

export type Transaction = {
  id: string
  description: string
  amount: MoneyValue
  createdDate: number
  state: TransactionState
  creatorName?: string
  iconUrl?: string
  icon?: IconCode
  avatar?: ReactNode
}

export type Card = {
  id: string
  imageUrl: string
  subtitle: string
  title: string
}

export type CardType = 'EXTERNAL' | 'INTERNAL'

export type Region = {
  code: number
  countryCode: string
  description: string
}

type ContainerSuggestion = {
  type: 'container'
  /**
   * An identifier which is used when issuing a request for nested "final" suggestions.
   */
  id: string
  /**
   * Additional human readable description, usually contains address grouping information,
   * e.g. how many addresses can be found inside if user wants to dig deeper using this
   * suggestion's id.
   */
  description: string
  /**
   * A human readable string representation for particular address suggestion.
   */
  result: string
}

export type FinalSuggestion = {
  type: 'final'
  /**
   * An identifier which is used when issuing a request to obtain address values based on this "final" suggestion.
   */
  id: string
  /**
   * A human readable string representation for particular address suggestion.
   */
  result: string
  /**
   * Additional human readable description
   */
  description: string | null
}
export type AddressSuggestion = ContainerSuggestion | FinalSuggestion

export type AddressSuggestions = Array<AddressSuggestion>

export type AddressCountryConfig = {
  availableCountries: Array<string>
  countriesSearchableByPostCode: Array<string>
  countriesSearchableBySubString: Array<string>
}

/**
 * Data for country states, e.g. California of United States or Queensland of Australia
 */
export type CountryState = {
  /**
   * Human readable label, e.g. "California"
   */
  name: string
  /**
   * Non-human readable property (e.g. for "California" it would be "CA") which is supposed to be used during communication with backend,
   * we store it in address dto under `region` property for certain countries
   * @see {CountriesWithLoadableStates} The countries for which we expect to have the code in the `region` property.
   */
  code: string
  /**
   * Whether the state of the country is supported by Revolut or should be prohibited from being used.
   */
  supported: boolean
}

export type CountryStates = Array<CountryState>

export type CountrySelectorDto = {
  visible: 'true' | 'false'
  editable: 'true' | 'false'
  preselected?: string
}

export type CountrySelector = {
  visible: boolean
  editable: boolean
  preselected?: string
}

export type AddressInputValue = {
  streetLine1?: string
  streetLine2?: string
  street?: string
  houseNumber?: string
  houseName?: string
  flat?: string
  city?: string
  region?: string
  postcode?: string
  neighbourhood?: string
  country: string
}

export type AddressInputItem = {
  type: FlowViewItemType.AddressInput
  id: string
  required: boolean
  countrySelector: CountrySelectorDto
  countries?: Country[]
  value?: AddressInputValue
  cityHint?: string
  countryHint: string
  flatHint?: string
  houseNameHint?: string
  houseNumberHint?: string
  neighbourhoodHint?: string
  postcodeHint?: string
  regionHint?: string
  streetLine1Hint?: string
  streetLine2Hint?: string
  streetNameHint?: string
  optionalFieldHint?: string
  addressPickerHint?: string
  postcodePickerHint?: string
  cancelButtonHint?: string
  emptyResultsHint?: string
  noResultsHint?: string
  genericErrorSubmitHint?: string
  tryAgainHint?: string
  searchBoxHint?: string
  noSuitableAddressesHint?: string
  enterAddressManuallyHint?: string
  requiredFieldHint: string
  streetLine1MaxLengthHint: string
  streetLine2MaxLengthHint: string
  streetAddressInvalidCharactersHint: string
  cityMaxLengthHint: string
  cityInvalidCharactersHint: string
  validationResult?: AddressValidationResult
}

export type WidgetProgressionItem = {
  id: string
  type: FlowViewItemType.WidgetProgression
  ticketId: string
}

export type AddressValidationResult =
  | { type: 'success' }
  | { type: 'invalid_address'; message: string; closeButtonLabel: string }
  | { type: 'invalid_address_postcode'; message: string; closeButtonLabel: string }
  | {
      type: 'ineligible_address'
      title: string
      description: string
      closeButtonLabel: string
    }

export type ItemValidationResult = {
  isValid: boolean
  message?: string
}

export type FLowViewItemDynamicGrouped =
  | MultiChoiceItemLegacy
  | SingleChoiceInputItemLegacy
  | SingleChoiceTextInputItemLegacy

export type FlowViewItemDynamicSingle =
  | CameraImageItem
  | CardInputItem
  | ChooserItem
  | DateInputItem
  | FilesUploadItem
  | MoneyInputItem
  | PhoneInputItem
  | StarRatingInputItem
  | TextInputItem
  | TimeInputItem
  | YesNoInputItem
  | CountryInputItem
  | CurrencyInputItem
  | TransactionInputItem
  | EntitiesSelectionItem
  | CardSelectionItem
  | SignatureInput
  | SingleSelectionItem
  | MultiSelectionItem
  | AddressInputItem
  | EditableListItem

export type FlowViewItemDynamic = FLowViewItemDynamicGrouped | FlowViewItemDynamicSingle

export type FlowViewItemStatic =
  | DocumentItem
  | ImageItem
  | TextItem
  | TransactionCellItem
  | ListItem
  | CardCellItem
  | EntityCellItem
  | TableItem
  | WidgetProgressionItem
  | EntitiesListItem
  | EntitiesSummaryItem

export type FlowViewItem = FlowViewItemDynamic | FlowViewItemStatic

export type TextItem = {
  id: string
  type: FlowViewItemType.TextInfo
  text: string
  style: TextStyle
}

export type ImageItem = {
  id: string
  type: FlowViewItemType.Image
  url?: string
  icon?: Icon
  content: FlowViewItemImageContent
}

export type Shape = {
  linesCount: number
  expandable: boolean
}

export type TextInputItem = {
  type: FlowViewItemType.TextInput
  id: string
  required: boolean
  hint: string
  textTransformation: 'DEFAULT' | 'UPPERCASE'
  underlineText?: string
  mask?: string
  placeholder?: string
  validations?: Validation[]
  value?: StringValue
  shape?: Shape
}

export type MoneyInputItem = {
  type: FlowViewItemType.MoneyInput
  id: string
  required: boolean
  searchHint?: string
  underlineText?: string
  currencies: string[]
  currencyOptions: Currency[]
  value?: MoneyValue
}

export type SelectionGroupOption = {
  id: string
  name: string
  selectionOptionIds: Array<SelectionOption['id']>
}

export type SelectionOption = {
  id: string
  title?: string
  description?: HtmlString
  assetUrl?: string
  icon?: Icon
  labels?: SelectionOptionLabel[]
  visible?: boolean
}

export type SelectionOptionLabel = {
  title: string
  icon?: {
    name?: string
    countryCode?: string
    url: string
  }
}

type SelectionCustomOption = {
  id: string
  hint?: string
}

type SelectionNoneApplyOption = {
  id: string
  title: string
}

export type SingleSelectionItem = {
  type: FlowViewItemType.SingleSelection
  id: string
  required: boolean
  searchHint?: string
  style: 'RADIO_BUTTON' | 'CONTINUE_BUTTON' | 'DROPDOWN'
  optionsSeparationStyle: 'JOINT' | 'SPACED'
  assetStyle?: 'SQUARE' | 'ROUNDED_SQUARE'
  options: SelectionOption[]
  customOption?: SelectionCustomOption
  groups: SelectionGroupOption[]
  value?: SingleSelectionValue
  hint?: string
}

export type MultiSelectionItem = {
  type: FlowViewItemType.MultiSelection
  id: string
  required: boolean
  searchHint?: string
  options: SelectionOption[]
  customOption?: SelectionCustomOption
  groups?: SelectionGroupOption[]
  noneApplyOption?: SelectionNoneApplyOption
  requiredAllOptionsSelected?: boolean
  value?: MultiSelectionValue
}

export type SingleChoiceInputItemLegacy = {
  type: FlowViewItemType.SingleChoiceLegacy
  id: string
  required: boolean
  group: number
  description?: string
  searchHint?: string
  value?: BooleanValue
}

export type SingleChoiceTextInputItemLegacy = {
  type: FlowViewItemType.SingleChoiceTextInputLegacy
  id: string
  required: boolean
  group: number
  hint: string
  underlineText?: string
  validation?: Validation
  searchHint?: string
  value?: SingleChoiceTextValue
}

export type MultiChoiceItemLegacy = {
  type: FlowViewItemType.MultiChoiceLegacy
  id: string
  required: boolean
  group: number
  title?: string
  description?: string
  descriptionHtml?: HtmlString
  searchHint?: string
  value?: BooleanValue
}

export type ChooserItem = {
  type: FlowViewItemType.Chooser
  id: string
  required: boolean
  hint: string
  searchHint?: string
  searchTitle: string
  options?: ChooserOption[]
  value?: StringValue
}

export type ChooserOption = {
  id: string
  text: string
}

export type DocumentItem = {
  type: FlowViewItemType.Document
  id: string
  subtitle: string
  url: string
  openRequired: boolean
  buttonTitle: string
  value?: DocumentValue
}

export type FilesUploadItem = {
  type: FlowViewItemType.FilesUpload
  id: string
  required: boolean
  buttonTitle: string
  value?: FilesValue
}

export type CameraImageItem = {
  type: FlowViewItemType.CameraImage
  id: string
  required: boolean
  defaultSource: CameraImageSource
  buttonTitle: string
  value?: FilesValue
}

export type CardInputItem = {
  type: FlowViewItemType.CardInput
  id: string
  hint: string
  required: boolean
  value?: CardNumberValue
}

export type DateInputItem = {
  type: FlowViewItemType.DateInput
  id: string
  hint: string
  required: boolean
  value?: DateValue
}

export type TimeInputItem = {
  type: FlowViewItemType.TimeInput
  id: string
  hint: string
  required: boolean
  value?: TimeValue
}

export type PhoneInputItem = {
  type: FlowViewItemType.PhoneInput
  id: string
  hint: string
  dropdownHint?: string
  searchHint?: string
  required: boolean
  regions: Region[]
  value?: PhoneValue
}

export type StarRatingInputItem = {
  type: FlowViewItemType.StarRatingInput
  id: string
  required: boolean
  value?: StarRatingValue
  negativeLabel?: string
  positiveLabel?: string
}

export type YesNoInputItem = {
  type: FlowViewItemType.YesNoInput
  id: string
  required: boolean
  yesTitle?: string
  noTitle?: string
  value?: BooleanValue
}

export type CountryInputItem = {
  type: FlowViewItemType.CountryInput
  id: string
  required: boolean
  hint?: string
  style?: 'DROPDOWN' | 'LIST'
  countries: Country[]
  searchHint?: string
  value?: StringValue
}

export type CurrencyInputItem = {
  type: FlowViewItemType.CurrencyInput
  id: string
  required: boolean
  currencies: Currency[]
  searchHint?: string
  value?: StringValue
}

export type SelectionStyle = 'SINGLE_SELECTION' | 'MULTI_SELECTION'

export type TransactionInputItem = {
  type: FlowViewItemType.TransactionInput
  id: string
  required: boolean
  maxSelected: number
  searchHint?: string
  value?: TransactionsValue
  preselectedTransactionId?: string | string[]
  style?: SelectionStyle
  // Legacy, to remove after BE-driven transaction search release
  content: { source: string; properties: PropertyFilter[] }
}

export type CardSelectionItem = {
  type: FlowViewItemType.CardSelection
  required: boolean
  id: string
  value?: CardSelectionValue
}

export type CardCellItem = {
  type: FlowViewItemType.CardCell
  id: string
  cardId: string
  cardType: CardType
}

export type EntityCellContent = {
  id: string
  title: string
  assetUrl?: string
  description?: string
  icon?: Icon
  moreInfoLink?: string
}

export type EntityCellItem = {
  id: string
  type: FlowViewItemType.EntityCell
  entityType?: EntityType
  entityIdSourceParam?: string
  defaultContent?: EntityCellContent
}

export type EntitiesSummaryItem = {
  id: string
  type: FlowViewItemType.EntitiesSummary
  entityType: EntityType
  includedEntityIdsSourceParam: string
  action?: Action
  onActionClick: (actionId?: string) => void
}

export type EntitiesSummaryHeader = {
  title: string
  subtitle: string
  iconEntityId: string
}

export type SignatureInput = {
  type: FlowViewItemType.SignatureInput
  id: string
  required: boolean
  label: string
  placeholder: string
  validations: Validation[]
  outerText?: string
  value?: StringValue
}

export type TransactionCellItem = {
  type: FlowViewItemType.TransactionCell
  id: string
  transactionId: string
}

export type Link = {
  title?: string
  hyperlink: string
}

export type ListElement = {
  type: 'ELEMENT'
  id: string
  title: string
  description?: string
  descriptionHtml?: HtmlString
  icon?: Icon
  link?: Link
}

export type ListItem = {
  type: FlowViewItemType.List
  id: string
  title?: string
  style: ListItemStyle
  linkStyle?: ListLinkStyle
  items: ListElement[]
}

export type TableItemElement = {
  id: string
  title: string
  value: string
}

export type TableItem = {
  id: string
  type: FlowViewItemType.TableItem
  items: TableItemElement[]
}

export type EntitiesListItem = {
  id: string
  type: FlowViewItemType.EntitiesList
  entityType: EntityType
  title?: string
  initialDisplaySize?: number
}

export type FileItem = {
  id: string
  name: string
  source?: File
  uploadState?: UploadState
}

export type FlowViewItemValue =
  | BooleanValue
  | CardNumberValue
  | DateValue
  | DocumentValue
  | FilesValue
  | MoneyValue
  | PhoneValue
  | SingleChoiceTextValue
  | StarRatingValue
  | StringValue
  | TimeValue
  | SingleSelectionValue
  | MultiSelectionValue

export type SingleChoiceTextValue = {
  selected: boolean
  content: {
    value?: string
  }
}

export type StarRatingValue = {
  value: number
}

export type PhoneValue = {
  code: string
  number: string
}

export type TimeValue = {
  value: string
}

export type DateValue = {
  value: string
}

export type CardNumberValue = {
  value: string
}

export type FilesValue = {
  files: FileItem[]
}

export type DocumentValue = {
  opened: boolean
}

export type StringValue = {
  value: string
}

export type MoneyValue = {
  amount: number
  currency: string
}

export type BooleanValue = {
  value: boolean
}

export type TransactionsValue = {
  transactions: Transaction[]
}

export type CardSelectionValue = {
  cards: Pick<Card, 'id'>[]
}

export type SingleSelectionValue = {
  optionId: string
  customOption?: string
}

export type MultiSelectionValue = {
  optionIds: string[]
  customOption?: string
}

export type EditableListItemValue = {
  items: Array<{
    id: string
    selected: boolean
    value?: string
  }>
}

export type EntitiesSelectionItemValue = {
  optionIds: string[]
}

export enum TextInputValidationType {
  TEXT = 'TEXT',
  EXTERNAL = 'EXTERNAL',
}

export type Validation = RegexValidation | ExternalValidation

export type RegexValidation = {
  type: TextInputValidationType.TEXT
  message: string
  pattern: string
}

export type ExternalValidation = {
  type: TextInputValidationType.EXTERNAL
  inquiry: string
  overrideMessage?: string
  params?: Array<{ name: string; value: string }>
}

export type EntityType = 'DEVICE' | 'INTERNAL_CARD' | 'EXTERNAL_CARD' | 'TRANSACTION'

export type EntitiesSelectionItem = {
  id: string
  type: FlowViewItemType.EntitiesSelection
  required: boolean
  entityType: EntityType
  maxSelectedOptions: number
  value?: EntitiesSelectionItemValue
  style?: SelectionStyle
}

export type Entity = {
  id: string
  title: string
  description?: string
  secondaryDescription?: string
  icon?: Icon
  assetUrl?: string
  moreInfoLink?: string
  createdDate?: number
}

export type EditableListStyle = 'DEFAULT' | 'WARNING' | 'ERROR' | 'PENDING'

export type EditableOption = {
  id: string
  required: boolean
  title: string
  style: EditableListStyle
  icon?: Partial<Icon>
  valuePlaceholder?: string
}

export type EditableListItem = {
  id: string
  type: FlowViewItemType.EditableList
  required: boolean
  title: string
  value?: EditableListItemValue
  items: EditableOption[]
  actions?: Action[]
  addActionId?: string
  emptyListPlaceholder?: string
  onActionClick: (actionId?: string) => void
}

// Legacy, to remove after BE-driven transaction search release
export type DateTimeExpression =
  | { type: DateTimeExpressionType.Value; value: number }
  | { type: DateTimeExpressionType.Now }

export type LocalDateExpression =
  | { type: DateTimeExpressionType.Value; value: string }
  | { type: DateTimeExpressionType.Now }

export type LocalTimeExpression =
  | { type: DateTimeExpressionType.Value; value: string }
  | { type: DateTimeExpressionType.Now }

export type QuantitativeFilterValue =
  | { type: FilterValueType.Int32; value: number }
  | { type: FilterValueType.Int64; value: number }
  | { type: FilterValueType.DateTime; value: DateTimeExpression }
  | { type: FilterValueType.Date; value: LocalDateExpression }
  | { type: FilterValueType.Time; value: LocalTimeExpression }

export type SimpleFilterValue =
  | { type: FilterValueType.String; value: string }
  | { type: FilterValueType.Boolean; value: boolean }
  | QuantitativeFilterValue

export type PropertyFilter = (
  | { type: PropertyFilterType.Eq; value: SimpleFilterValue }
  | { type: PropertyFilterType.In; values: SimpleFilterValue[] }
  | {
      type: PropertyFilterType.Between
      from: QuantitativeFilterValue
      to: QuantitativeFilterValue
      fromInclusive: boolean
      toInclusive: boolean
    }
  | { type: PropertyFilterType.Gt; value: QuantitativeFilterValue }
  | { type: PropertyFilterType.Lt; value: QuantitativeFilterValue }
) & {
  name: string
  negated: boolean
}

export type AdditionalFilters = {
  mcc?: string
  chargebackEligible?: boolean
}

export type FilterDateRange = { from: number; to: number }

export type TicketProgressStepLineStyle = 'SOLID' | 'DASHED'

export type TicketProgressStepColor =
  | 'HIGHLIGHTED'
  | 'REGULAR_PRIMARY'
  | 'REGULAR_SECONDARY'
  | 'DISABLED_PRIMARY'
  | 'DISABLED_SECONDARY'
  | 'PINK'
  | 'ORANGE'
  | 'WHITE'
  | 'RED'
  | 'BLACK'
  | 'BLUE'
  | 'GREEN'

export type TicketProgressType = 'WIDGET_PROGRESSION'

export type TicketProgressStep = {
  title: string
  description?: string
  date?: number
  stepColor?: TicketProgressStepColor
  lineStyle?: TicketProgressStepLineStyle
}

export type TicketProgress = {
  headerTitle: string
  currentStepIndex: number
  color?: string
  type: TicketProgressType
  steps: TicketProgressStep[]
}
