import React from 'react'

import { RadioGroupState, Cell, Checkbox, HStack, VStack } from '@revolut/ui-kit'

import { MultiSelectionItem } from 'types'
import { HtmlStringComponent } from '../common'
import { SelectionAsset } from '../SingleSelection/SelectionAsset'
import { OptionsWrapper } from '../SingleSelection/OptionsWrapper'

interface Props extends Pick<MultiSelectionItem, 'options'> {
  getInputProps: RadioGroupState<string>['getInputProps']
}

export const MultiSelectionOptions = ({ options, getInputProps }: Props) => {
  return (
    <OptionsWrapper areOptionsSpaced={false}>
      {options.map(option => (
        <Cell use="label" key={option.id}>
          <Checkbox {...getInputProps({ value: option.id })} data-testid={option.id}>
            <HStack align="center" space="s-16">
              {(option.icon || option.assetUrl) && (
                <SelectionAsset icon={option.icon} assetUrl={option.assetUrl} />
              )}
              <VStack>
                <Checkbox.Label>{option.title}</Checkbox.Label>
                {option.description?.value && (
                  <Checkbox.Description>
                    <HtmlStringComponent htmlString={option.description} />
                  </Checkbox.Description>
                )}
              </VStack>
            </HStack>
          </Checkbox>
        </Cell>
      ))}
    </OptionsWrapper>
  )
}
