import { getBrowserLocale } from '../FlowPage/helpers'
import { FALLBACK_COUNTRIES } from '../FlowPage/Controls/common/constants/fallbackCountries'
import {
  Flow,
  FileItem,
  Transaction,
  AdditionalFilters,
  Card,
  CardType,
  Entity,
  EntitiesSummaryHeader,
  AddressSuggestions,
  AddressCountryConfig,
  CountryStates,
  AddressInputValue,
  AddressValidationResult,
  TicketProgress,
  ItemValidationResult,
} from '../types'

// If flow destination is 'CHAT' and form is completed, then
// submitFlow api in biz web app returns plain object with chat ticketId = { id: string }
declare const opaque: unique symbol
export type BusinessWebChatTicketIdResponse = {
  id: string
  readonly [opaque]: 'business_web_chat_ticket_id_response'
}

export type BusinessWebStaticFormResponse = {
  id: string
  response: any
}

export type SubmitFlowResponse =
  | Flow
  | BusinessWebChatTicketIdResponse
  | BusinessWebStaticFormResponse

export const isBusinessWebChatTicketIdResponse = (
  response: SubmitFlowResponse,
): response is BusinessWebChatTicketIdResponse => {
  const keys = Object.keys(response)
  return keys.length === 1 && keys[0] === 'id'
}

export const isBusinessWebStaticFormResponse = (
  response: SubmitFlowResponse,
): response is BusinessWebStaticFormResponse => {
  return 'response' in response
}

export type Api = {
  loadFlow: (flowId: string, queryString?: string) => Promise<Flow | undefined>
  submitFlow: (flow: Flow) => Promise<SubmitFlowResponse>
  uploadFile: (file: File, flowId: string) => Promise<FileItem>
  getCards: (formId: string, itemId: string) => Promise<Card[]>
  getCard: (formId: string, cardId: string, cardType: CardType) => Promise<Card>
  getAddressSuggestions: (params: {
    countryCode: string
    text: string
    parent: null | string
    limit: number
  }) => Promise<AddressSuggestions>
  getAddressValuesBySuggestion: (params: {
    countryCode: string
    addressId: string
  }) => Promise<AddressInputValue>
  getCountryStates: (patams: { countryCode: 'AU' | 'US' }) => Promise<CountryStates>
  getCountriesConfig: () => Promise<AddressCountryConfig>
  getAddressesByPostcode: (
    countryCode: string,
    postcode: string,
  ) => Promise<AddressInputValue[]>
  validateAddress: (address: AddressInputValue) => Promise<AddressValidationResult>
  getTransaction: (transactionId: string) => Promise<Transaction>
  getTransactions: (options: {
    flowId: string
    itemId: string
    createdBefore: number
    searchKeyword: string
    transactionIds?: string[]
    excludeTransactionIds?: string[]
    // transactionId is needed for legacy mechanism used in HC troubleshooters
    transactionId?: string
  }) => Promise<{ createdSince: number; last: boolean; transactions: Transaction[] }>
  getEntities?: (flowId: string, itemId: string) => Promise<Entity[]>
  getEntitiesSummary?: (
    flowId: string,
    itemId: string,
    requestParams: Record<string, string>,
  ) => Promise<EntitiesSummaryHeader>
  // Legacy, to remove after BE-driven transaction search release:
  getTransactionsLegacy?: (
    params: {
      count: number
      from?: number
      to?: number
    },
    additionalFilters?: AdditionalFilters,
  ) => Promise<Transaction[]>
  getTicketProgress?: (ticketId: string) => Promise<TicketProgress>
  getCurrentLocale?: () => string
  validateItem: (
    formId: string,
    viewId: string,
    itemId: string,
    value: string,
  ) => Promise<ItemValidationResult>
}

const api: Api = {
  loadFlow: async (flowId: string, queryString = ''): Promise<Flow | undefined> => {
    const result = await fetch(`/api/forms/${flowId}${queryString}`, {
      headers: {
        'accept-language': getBrowserLocale(),
      },
    })

    if (result.status === 404) {
      return undefined
    }

    if (!result.ok) {
      throw Error('Unable to load the form.')
    }

    return result.json()
  },

  submitFlow: async (flow: Flow) => {
    const result = await fetch(`/api/forms/${flow.id}/submit`, {
      method: 'POST',
      body: JSON.stringify(flow),
      headers: {
        'accept-language': getBrowserLocale(),
      },
    })

    if (!result.ok) {
      throw Error('Unable to submit the form.')
    }

    return result.json()
  },

  uploadFile: async (file: File, flowId: string): Promise<FileItem> => {
    const formData = new FormData()
    formData.append('body', file)

    const result = await fetch(`/api/forms/${flowId}/uploads`, {
      method: 'POST',
      body: formData,
    })

    if (!result.ok) {
      throw Error('Unable to upload file.')
    }

    return result.json()
  },

  getTransactions: () =>
    Promise.resolve({ createdSince: Date.now(), last: true, transactions: [] }),

  getCards: () => Promise.resolve<Card[]>([]),

  getCard: () => Promise.reject(),

  getAddressSuggestions: () => Promise.resolve([]),

  getCountryStates: () => Promise.resolve([]),

  getAddressValuesBySuggestion: () => Promise.reject(),

  getAddressesByPostcode: () => Promise.resolve([]),

  validateAddress: () => Promise.resolve({ type: 'success' }),

  getCountriesConfig: () =>
    Promise.resolve({
      availableCountries: FALLBACK_COUNTRIES.map(({ code }) => code),
      countriesSearchableByPostCode: [],
      countriesSearchableBySubString: [],
    }),

  getTransaction: (_transactionId: string) => {
    return Promise.reject()
  },

  // Legacy, to remove after BE-driven transaction search release:
  getTransactionsLegacy: () => Promise.resolve([]),

  getEntities: () => Promise.resolve([]),

  getEntitiesSummary: async (flowId, itemId, requestParams) => {
    const parameters = requestParams
      ? Object.fromEntries(
          Object.entries(requestParams).map(([key, value]) => [key, [value]]),
        )
      : {}

    const response = await fetch(`/api/forms/${flowId}/entitiesSummary`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        itemId,
        parameters,
      }),
    })

    if (!response.ok) {
      throw Error('Unable to load entities summary.')
    }

    return response.json()
  },

  getTicketProgress: () => Promise.reject(),

  getCurrentLocale: getBrowserLocale,

  validateItem: async (formId, viewId, itemId, value) => {
    const response = await fetch(
      `/api/forms/${formId}/views/${viewId}/items/${itemId}/validate`,
      {
        method: 'POST',
        body: JSON.stringify({
          value: {
            type: 'STRING',
            value,
          },
        }),
      },
    )

    const result = await response.json()

    if (!response.ok) {
      throw Error(result.message ?? 'Invalid value')
    }

    return result
  },
}

export default api
