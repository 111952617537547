import React from 'react'
import { Prompt as UIPrompt, Header, Image, Icon, Avatar, Flex } from '@revolut/ui-kit'
import styled from 'styled-components'
import { HtmlStringComponent } from '../Controls/common'
import { PromptView } from '../../types'

import FlowActionButtons from '../FlowActionButtons'

export type Props = {
  promptView: PromptView
  onClose: () => void
  onActionButtonClick: (actionId?: string) => void
}

const StyledImageBox = styled(Flex)`
  width: 100%;
  justify-content: center;
`

const Prompt = ({ promptView, onClose, onActionButtonClick }: Props) => {
  const { title, subtitleHtml, image, closeable } = promptView

  const hasIcon = Boolean(image?.icon?.name)
  const hasFlag = Boolean(!hasIcon && image?.icon?.countryCode)
  const hasImageUrl = Boolean(!hasIcon && !hasFlag && image?.url)

  return (
    <UIPrompt open onClose={() => closeable && onClose()} preventUserClose={!closeable}>
      <UIPrompt.Header>
        <Header variant="item">
          {closeable && <Header.CloseButton onClick={onClose} />}
          <Header.Title>{title}</Header.Title>
          {subtitleHtml && (
            <Header.Description>
              <HtmlStringComponent htmlString={subtitleHtml} />
            </Header.Description>
          )}
        </Header>
      </UIPrompt.Header>
      {hasIcon && <Icon name={image?.icon?.name} size={200} m="auto" />}
      {hasFlag && (
        <StyledImageBox>
          <Avatar size={96} image={image?.icon?.url} />
        </StyledImageBox>
      )}
      {hasImageUrl && <Image src={image?.url} size={200} m="auto" />}
      <UIPrompt.Actions>
        <FlowActionButtons onClick={onActionButtonClick} actions={promptView.actions} />
      </UIPrompt.Actions>
    </UIPrompt>
  )
}

export default Prompt
