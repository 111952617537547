import React from 'react'
import { RootProvider } from '@revolut/ui-kit'

import { BrowserRouter } from 'react-router-dom'
import { NavigationObstacleProvider, ImagePreviewCacheProvider } from '.'
import { ReactQueryProvider } from './ReactQueryProvider'

type Props = {
  children: React.ReactNode
}

export const StandaloneProviders = ({ children }: Props) => (
  <ReactQueryProvider>
    <RootProvider>
      <NavigationObstacleProvider>
        <ImagePreviewCacheProvider>
          <BrowserRouter>{children}</BrowserRouter>
        </ImagePreviewCacheProvider>
      </NavigationObstacleProvider>
    </RootProvider>
  </ReactQueryProvider>
)
