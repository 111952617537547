import React from 'react'
import { Group, Checkbox, Item, Avatar, Image, ItemSkeleton } from '@revolut/ui-kit'

import { CardSelectionValue } from '../../../types'
import { useFlowId } from '../../../providers'
import { useCardsQuery } from './useCardsQuery'
import { ItemError } from '../common'

const DEFAULT_VALUE = { cards: [] }

type Props = {
  id: string
  disabled: boolean
  value?: CardSelectionValue
  changeValue: (value: CardSelectionValue) => void
}

const CardSelection = ({ id, disabled, value = DEFAULT_VALUE, changeValue }: Props) => {
  const flowId = useFlowId()
  const cardsQuery = useCardsQuery(id, flowId)
  const selectedCardsLookup: Record<string, boolean> = value.cards.reduce(
    (acc, card) => ({ ...acc, [card.id]: true }),
    {},
  )

  const changeCardSelected = (cardId: string, selected: boolean) => {
    if (selected) {
      changeValue({
        cards: [...value?.cards, { id: cardId }],
      })
    } else {
      changeValue({
        cards: value.cards.filter(card => cardId !== card.id),
      })
    }
  }

  switch (cardsQuery.status) {
    case 'loading':
      return (
        <Group>
          <ItemSkeleton />
        </Group>
      )
    case 'success':
      return (
        <Group>
          {cardsQuery.data.map(({ id: cardId, imageUrl, title, subtitle }) => (
            <Item key={cardId} use="label">
              <Item.Prefix>
                <Checkbox
                  disabled={disabled}
                  checked={Boolean(selectedCardsLookup[cardId])}
                  aria-labelledby={`${cardId}-title`}
                  aria-describedby={`${cardId}-description`}
                  onChange={event => changeCardSelected(cardId, event.target.checked)}
                />
              </Item.Prefix>
              <Item.Avatar>
                <Avatar color="white">
                  <Image borderRadius="2px" src={imageUrl} />
                </Avatar>
              </Item.Avatar>

              <Item.Content>
                <Item.Title id={`${cardId}-title`}>{title}</Item.Title>
                <Item.Description id={`${cardId}-description`}>
                  {subtitle}
                </Item.Description>
              </Item.Content>
            </Item>
          ))}
        </Group>
      )
    case 'error':
      return <ItemError onRetry={() => cardsQuery.refetch()} />
    case 'idle':
    default:
      return null
  }
}

export default CardSelection
