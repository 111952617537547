import React, { Component, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import { Box, Token } from '@revolut/ui-kit'
import '@revolut/ui-kit/styles.css'
import 'styles.css'

import { ErrorPage } from 'ErrorPage'
import { FlowPage } from 'FlowPage'
import { NoCameraPage } from 'NoCameraPage'
import { NotFoundPage } from 'NotFoundPage'
import { Route as RoutePath, REVOLUT_WEBSITE } from 'appConstants'
import { StandaloneProviders } from './providers/StandaloneProviders'

const StyledMainContainer = styled(Box)`
  height: 100%;
  overflow-y: scroll;
  background-color: ${Token.color.layoutBackground};
  isolation: isolate;
`

const RedirectToRevolutWebsite = () => {
  useEffect(() => {
    window.location.assign(REVOLUT_WEBSITE)
  }, [])

  return null
}

type Props = {
  children?: React.ReactNode
}

type State = {
  hasError: boolean
}

export default class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    return (
      <StyledMainContainer>
        {this.state.hasError ? (
          <ErrorPage />
        ) : (
          this.props.children || (
            <Switch>
              <Route path={RoutePath.Flow}>
                {({ match }) => match && <FlowPage flowId={match.params.flowId} />}
              </Route>
              <Route path={RoutePath.NotFound}>
                <NotFoundPage />
              </Route>
              <Route path={RoutePath.NoCamera}>
                <NoCameraPage />
              </Route>
              <Route path="/" exact>
                <RedirectToRevolutWebsite />
              </Route>
            </Switch>
          )
        )}
      </StyledMainContainer>
    )
  }
}

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)
  root.render(
    <StandaloneProviders>
      <App />
    </StandaloneProviders>,
  )
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://5b40bafa9114426aaa1cf885d7583477@o4508601944047696.ingest.de.sentry.io/4508602000539733',
    release: process.env.npm_package_version,
    ignoreErrors: ['top.GLOBALS'],
    integrations: integrations => [
      ...integrations,
      Sentry.browserTracingIntegration(),
      Sentry.dedupeIntegration(),
      Sentry.extraErrorDataIntegration({ depth: 6 }),
    ],
  })
}
