import React from 'react'

import { useLocation } from 'react-router-dom'
import { EntitiesSummaryItem } from 'types'
import { Item, ItemSkeleton } from '@revolut/ui-kit'
import { useFlowId } from '../../../providers'
import { TransactionAvatar } from '../common/TransactionItem/TransactionAvatar'
import { useTransactionQuery } from '../TransactionCell/useTransactionQuery'
import { useEntitiesSummaryQuery } from './useEntitiesSummaryQuery'
import { ItemError } from '../common'

type Props = EntitiesSummaryItem

const EntitiesSummary = ({ id, action, onActionClick }: Props) => {
  const formId = useFlowId()
  const { search } = useLocation()
  const requestParams = Object.fromEntries(new URLSearchParams(search))
  const summaryQuery = useEntitiesSummaryQuery(formId, id, requestParams)
  const { data: summary, status: summaryStatus } = summaryQuery

  const transactionQuery = useTransactionQuery(summary?.iconEntityId)
  const { data: transaction, status: transactionStatus } = transactionQuery

  if (summaryStatus === 'idle') {
    return null
  }

  if (summaryStatus === 'loading' || transactionStatus === 'loading') {
    return <ItemSkeleton />
  }

  if (summaryStatus === 'error' || transactionStatus === 'error') {
    return (
      <ItemError
        onRetry={() => {
          summaryStatus === 'error' && summaryQuery.refetch()
          transactionStatus === 'error' && transactionQuery.refetch()
        }}
      />
    )
  }

  if (summaryStatus === 'success' && transactionStatus === 'success') {
    return (
      <Item
        {...(action && {
          use: 'button',
          onClick: () => onActionClick(action.id),
        })}
        variant={action ? 'disclosure' : 'default'}
      >
        <Item.Avatar>
          <TransactionAvatar transaction={transaction} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{summary?.title}</Item.Title>
          <Item.Description>{summary?.subtitle}</Item.Description>
        </Item.Content>
      </Item>
    )
  }

  return null
}

export default EntitiesSummary
