import React, { FC, useCallback, useMemo } from 'react'
import { IntlProvider, DateInput as UIKitDateInput } from '@revolut/ui-kit'
import { format, parse } from 'date-fns'

import { useApi } from '../../../providers'
import { DateValue } from '../../../types'
import { getBrowserLocale } from '../../helpers'

const serverDateFormat = 'yyyy-MM-dd'

type Props = {
  value?: Pick<DateValue, 'value'>
  disabled: boolean
  hint?: string
  changeValue: (value: DateValue['value']) => void
}

const DateInput: FC<Props> = ({ value, disabled, changeValue, hint }) => {
  const handleChange = useCallback(
    (date: Date | null | undefined) => {
      changeValue(date ? format(date, serverDateFormat) : '')
    },
    [changeValue],
  )

  const api = useApi()
  const locale = useMemo(() => api.getCurrentLocale?.() || getBrowserLocale(), [api])

  return (
    <IntlProvider locale={locale}>
      <UIKitDateInput
        value={
          value?.value ? parse(value.value, serverDateFormat, new Date()) : undefined
        }
        label={hint}
        useIcon="CalendarDate"
        disabled={disabled}
        onChange={handleChange}
      />
    </IntlProvider>
  )
}

export default DateInput
