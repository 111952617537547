import React, { FC } from 'react'
import { Avatar, Flex, Icon } from '@revolut/ui-kit'
import styled from 'styled-components'

import { ImageItem } from 'types'
import { Img } from './styled'
import { useIsWidgetMode } from '../../../providers'

const ASSETS_URL = 'https://assets.revolut.com/'

const StyledImageBox = styled(Flex)`
  width: 100%;
  justify-content: center;
`

const Image: FC<Partial<ImageItem>> = ({ url, icon }) => {
  const isWidgetMode = useIsWidgetMode()
  const isAssetsImage = url?.startsWith(ASSETS_URL)

  // Standalone web forms support only https://assets.revolut.com/ image urls
  if (!isWidgetMode && !isAssetsImage && !icon) return null

  if (!icon) {
    return (
      <StyledImageBox>
        <Img src={url} alt="" />
      </StyledImageBox>
    )
  }

  if (icon.name) {
    return (
      <StyledImageBox>
        <Icon name={icon.name} size={300} />
      </StyledImageBox>
    )
  }

  if (icon.countryCode) {
    return (
      <StyledImageBox>
        <Avatar size={96} image={icon.url} />
      </StyledImageBox>
    )
  }

  return null
}

export default Image
