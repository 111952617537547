import React from 'react'
import { Image, ImageProps, Icon, Avatar } from '@revolut/ui-kit'
import { Icon as IconType, SingleSelectionItem } from 'types'

type AssetStyle = NonNullable<SingleSelectionItem['assetStyle']>

const ASSET_SIZE_PX_MAP: Record<AssetStyle, number> = {
  SQUARE: 60,
  ROUNDED_SQUARE: 80,
}

const getAssetStyleProps = (style: AssetStyle): ImageProps => {
  if (style === 'ROUNDED_SQUARE') {
    return { borderRadius: 'r12' }
  }
  return {}
}

interface Props {
  icon?: IconType
  assetUrl?: string
  style?: AssetStyle
}

export const SelectionAsset = ({ icon, assetUrl, style = 'ROUNDED_SQUARE' }: Props) => {
  if (!icon && !assetUrl) return null

  if (icon?.name) {
    return <Icon name={icon.name} size={ASSET_SIZE_PX_MAP[style]} />
  }
  if (icon?.countryCode) {
    return <Avatar size={60} image={icon.url} />
  }

  return (
    <Image
      size={ASSET_SIZE_PX_MAP[style] || 60}
      src={assetUrl}
      {...getAssetStyleProps(style)}
      scale="cover"
    />
  )
}
