import React, { FC, useMemo, useState, useCallback } from 'react'
import { Box, Checkbox, CheckboxGroup, Search, Subheader, VStack } from '@revolut/ui-kit'

import { includesSubstringCaseInsensitive } from '../../helpers'
import { CHOICE_ELEMENT_AMOUNT_TO_DISPLAY_SEARCHBAR } from '../../../appConstants'
import { MultiSelectionItem, MultiSelectionValue } from '../../../types'
import { MultiSelectionOptions } from './MultiSelectionOptions'
import { CustomOption } from '../SingleSelection/CustomOption'

export const CUSTOM_ANSWER_INPUT_TESTID = 'custom-answer-input-testid'

export type Props = MultiSelectionItem & {
  disabled: boolean
  changeValue: (value?: MultiSelectionValue) => void
}

const MultiSelection: FC<Props> = ({ disabled, changeValue, ...item }) => {
  const { options, groups, customOption, noneApplyOption, searchHint, value } = item
  const { optionIds: selectedOptionIds, customOption: customOptionValue } = value ?? {}

  const showCustomInput = customOption && selectedOptionIds?.includes(customOption.id)

  const noneApplyOptionInitiallySelected =
    noneApplyOption && selectedOptionIds?.includes(noneApplyOption.id)

  const [filterValue, setFilterValue] = useState('')

  const filteredOptions = useMemo(
    () =>
      options.filter(
        option =>
          (option.title && includesSubstringCaseInsensitive(option.title, filterValue)) ||
          (option.description &&
            includesSubstringCaseInsensitive(option.description.value, filterValue)),
      ),
    [filterValue, options],
  )

  const groupsWithOptions = useMemo(() => {
    if (!groups?.length) {
      return undefined
    }

    return groups.map(group => ({
      ...group,
      options: group.selectionOptionIds.map(id => {
        const foundOption = options.find(option => option.id === id)
        if (!foundOption) {
          throw new Error('Unable to locate option from the group')
        }
        return foundOption
      }),
    }))
  }, [options, groups])

  const changeCheckboxGroup = useCallback(
    (optionIds: string[] | null) => {
      if (optionIds === null) {
        return
      }

      if (noneApplyOption && optionIds.includes(noneApplyOption.id)) {
        // Selecting 'None of the above' should uncheck any other previously selected options, and vice versa.
        optionIds = noneApplyOptionInitiallySelected
          ? optionIds.filter(optionId => optionId !== noneApplyOption.id)
          : [noneApplyOption.id]
      }

      if (customOption && optionIds.includes(customOption.id)) {
        changeValue({
          optionIds,
          customOption: customOptionValue ?? '',
        })
        return
      }

      changeValue({ optionIds })
    },
    [
      changeValue,
      customOption,
      customOptionValue,
      noneApplyOption,
      noneApplyOptionInitiallySelected,
    ],
  )

  const changeCustomValue = useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      if (!selectedOptionIds) {
        return
      }
      changeValue({
        optionIds: selectedOptionIds,
        customOption: event.currentTarget.value,
      })
    },
    [changeValue, selectedOptionIds],
  )

  return (
    <>
      {options.length > CHOICE_ELEMENT_AMOUNT_TO_DISPLAY_SEARCHBAR && (
        <Search value={filterValue} placeholder={searchHint} onChange={setFilterValue} />
      )}
      <Box mt="s-16">
        <CheckboxGroup
          value={selectedOptionIds}
          disabled={disabled}
          onChange={changeCheckboxGroup}
        >
          {({ getInputProps }) => (
            <>
              {groupsWithOptions ? (
                <VStack>
                  {groupsWithOptions.map(group => (
                    <VStack key={group.id}>
                      <Subheader>{group.name}</Subheader>
                      <MultiSelectionOptions
                        options={group.options}
                        getInputProps={getInputProps}
                      />
                    </VStack>
                  ))}
                </VStack>
              ) : (
                <MultiSelectionOptions
                  options={filteredOptions}
                  getInputProps={getInputProps}
                />
              )}

              <CustomOption
                use={Checkbox}
                customOption={customOption}
                showInput={!!showCustomInput}
                disabled={disabled}
                showGroupsHeader={Boolean(groupsWithOptions)}
                customOptionValue={customOptionValue}
                changeCustomValue={changeCustomValue}
                getInputProps={getInputProps}
              />
              {noneApplyOption && (
                <Box p="s-16">
                  <Checkbox
                    {...getInputProps({ value: noneApplyOption.id })}
                    data-testid={noneApplyOption.id}
                  >
                    <Checkbox.Label>
                      {noneApplyOption?.title || 'None of the above'}
                    </Checkbox.Label>
                  </Checkbox>
                </Box>
              )}
            </>
          )}
        </CheckboxGroup>
      </Box>
    </>
  )
}

export default MultiSelection
