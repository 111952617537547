import React from 'react'
import { capitalize } from 'lodash'

import {
  Item,
  ItemProps,
  Checkbox,
  CheckboxGroupState,
  Text,
  textChain,
} from '@revolut/ui-kit'
import { Transaction } from 'types'
import { isTransactionStateInvalid } from '../../../../appConstants'
import { TransactionAvatar } from './TransactionAvatar'
import { formatDate, getCurrencyLabel } from './utils'

type Props<T> = {
  transaction: Transaction
  checkboxGroupInputProps?: ReturnType<CheckboxGroupState<T>['getInputProps']>
  handleClick?: (transactionId: string) => void
  isContinueButtonStyle?: boolean
} & ItemProps

export function TransactionItem<T>({
  transaction,
  checkboxGroupInputProps,
  handleClick,
  isContinueButtonStyle,
  ...itemProps
}: Props<T>) {
  const { description, createdDate, amount, state, creatorName } = transaction

  return (
    <Item
      {...itemProps}
      onClick={() => handleClick?.(transaction.id)}
      data-testid={transaction.id}
      {...(handleClick && { use: 'button' })}
      variant={isContinueButtonStyle ? 'disclosure' : 'default'}
    >
      {checkboxGroupInputProps && (
        <Item.Prefix>
          <Checkbox {...checkboxGroupInputProps} />
        </Item.Prefix>
      )}
      <Item.Avatar>
        <TransactionAvatar transaction={transaction} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{description}</Item.Title>
        <Item.Description>
          {textChain(formatDate(createdDate), creatorName, capitalize(state))}
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <Text
            textDecoration={isTransactionStateInvalid(state) ? 'line-through' : 'none'}
          >
            {getCurrencyLabel(amount)}
          </Text>
        </Item.Value>
      </Item.Side>
    </Item>
  )
}
