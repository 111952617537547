import React from 'react'

import { Item, Avatar, ItemSkeleton, Icon } from '@revolut/ui-kit'
import { EntityCellItem } from 'types'
import { DEFAULT_LIST_ICON } from '../../../appConstants'
import { ItemError } from '../common'
import { useEntities } from '../common/Entities/useEntities'

const EntityCell = ({ id, entityType }: EntityCellItem) => {
  const { enabled, loading, hasError, refetch, entities } = useEntities(id, entityType)

  if (!enabled) return null

  if (loading) {
    return <ItemSkeleton />
  }

  const entity = entities[0]
  const hasIcon = Boolean(entity?.icon?.name)
  const hasFlag = Boolean(!hasIcon && entity?.icon?.countryCode)

  if (!entity || hasError) return <ItemError onRetry={refetch} />

  return (
    <Item>
      <Item.Avatar>
        {hasIcon && (
          <Avatar>
            <Icon size={24} name={entity.icon?.name} />
          </Avatar>
        )}
        {hasFlag && <Avatar image={entity.icon?.url} />}
        {!hasIcon && !hasFlag && (
          <Avatar>
            <Icon size={24} name={DEFAULT_LIST_ICON} />
          </Avatar>
        )}
      </Item.Avatar>

      <Item.Content>
        <Item.Title>{entity.title}</Item.Title>
        <Item.Description>{entity.description}</Item.Description>
      </Item.Content>
    </Item>
  )
}

export default EntityCell
